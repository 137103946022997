import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import {
  updateGroupMember,
  removeGroupMember,
  fetchGroupMembers,
} from '../../actions/group';
import { money } from '../../core/money';
import Breadcrumbs from '../../components/Breadcrumbs';
import FAIcon from '../../components/Icon/FAIcon';
import CountBox from '../../components/CountBox';
import Divider from '../../components/Divider';
import Button from '../../components/Button';
import MemberRequest from '../../components/MemberRequests/Group';
import CircularProgress from '../../components/CircularProgress/CircularProgress';
import GroupMember from '../../components/GroupMember';
import AddTeamMemberDialog from './AddTeamMemberDialog';
import Pagination from '../../components/Pagination/Pagination';
import Link from '../../components/Link';
import { debounce, formatCredits, normalizeSpace } from '../../core/util';
import { borderColor, darkGray, teal500 } from '../../core/colors';
import { notify } from '../../actions/ui';
import { APIError } from '../../core/apiTransport';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerLink: {
    whiteSpace: 'nowrap',
    marginLeft: 15,
    fontSize: 14,
  },
  members: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  membersActions: {
    width: 200,
  },
  membersActionsTitle: {
    color: darkGray,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  membersList: {
    flex: 1,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20,
    borderTop: `1px solid ${borderColor}`,
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 25,
    '& span': {
      paddingTop: 15,
    },
  },
  counts: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      '& > *': {
        width: 170,
        paddingBottom: 10,
      },
    },
  },
  search: {
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
      width: 190,
    },
  },
}));

function Team({
  viewer,
  groupId,
  updateGroupMember,
  removeGroupMember,
  team,
  fetchGroupMembers,
  notify,
}) {
  const s = useStyles();

  const { members_awaiting: awaitingPage } = team;
  const {
    name,
    stats = {},
    html_url: htmlUrl,
    billing_account: billingAccount,
  } = team;

  const [addOpen, setAddOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [members, setMembers] = useState({ edges: [] });
  const [totalMembers, setTotalMembers] = useState(0);
  const [nameSearch, setNameSearch] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchPageMembers = useCallback(
    async ({ name = nameSearch } = {}) => {
      const members = await fetchGroupMembers(groupId, {
        page,
        name,
      });

      // if empty, decrease page
      if (page > 1 && !members.edges.length) {
        setPage(page - 1);
      }

      setMembers(members);
      setLoading(false);
    },
    [groupId, page, fetchGroupMembers]
  );

  const fetchTotalMembers = useCallback(async () => {
    const members = await fetchGroupMembers(groupId, {
      limit: 1,
    });
    setTotalMembers(members.pageInfo?.total);
  }, [groupId, fetchGroupMembers]);

  const debounceSearch = useCallback(
    debounce((name) => {
      fetchPageMembers({ name });
    }, 500),
    [fetchPageMembers]
  );

  useEffect(() => {
    fetchPageMembers({ name: nameSearch });
  }, [fetchPageMembers]);

  useEffect(() => {
    fetchTotalMembers();
  }, [fetchTotalMembers]);

  useEffect(() => {
    if (nameSearch === null) return;
    debounceSearch(nameSearch);
  }, [nameSearch]);

  const handleAddClose = () => {
    setAddOpen(false);
  };

  const handleAdd = () => {
    setAddOpen(true);
  };

  const fetchMembers = () => {
    fetchPageMembers({ name: nameSearch });
    fetchTotalMembers();
  };

  const removeGroupMemberAndFetch = (...params) => {
    setLoading(true);
    removeGroupMember
      .apply(this, params)
      .then(() => {
        fetchMembers();
      })
      .catch((e) => {
        if (e instanceof APIError) {
          e.rawError.map((error) => notify(error.message));
        }
        fetchMembers();
      });
  };

  const updateGroupMemberAndFetch = (...params) => {
    setLoading(true);
    updateGroupMember
      .apply(this, params)
      .then(() => {
        fetchMembers();
      })
      .catch((e) => {
        if (e instanceof APIError) {
          e.rawError.map((error) => notify(error.message));
        }
        fetchMembers();
      });
  };

  const awaitingMembers = useMemo(() => {
    if (!awaitingPage) return null;
    const awaiting = awaitingPage.edges
      .map((x) => x.node)
      .filter((x) => x.user);
    if (!awaiting.length) return null;

    return <MemberRequest onApprove={fetchMembers} memberRequests={awaiting} />;
  }, [awaitingPage]);

  const creditBalance = billingAccount
    ? money(billingAccount.credit_balance)
    : money({ cents: 0, currency: 'OFC' });

  const permissions = team.permissions || [];
  const viewerMember = members.edges.find(
    ({ node: m }) => m.user && m.user.id === viewer.id
  );
  const viewerRole = (viewerMember && viewerMember.role) || 'owner';

  const memberIDs = members.edges
    .map(({ node: m }) => m.user && m.user.id)
    .filter((x) => x);
  const addUserFilter = (suggestionCandidate) =>
    !memberIDs.includes(suggestionCandidate.id);

  const perms = {
    add: permissions.some((x) => x === 'add_member'),
    update: {
      owner: permissions.some((x) => x === 'update_owner'),
      admin: permissions.some((x) => x === 'update_admin'),
      member: permissions.some((x) => x === 'update_member'),
    },
    remove: {
      owner: permissions.some((x) => x === 'remove_owner'),
      admin: permissions.some((x) => x === 'remove_admin'),
      member: permissions.some((x) => x === 'remove_member'),
    },
    allowedRoles: permissions
      .filter((x) => x.startsWith('update_'))
      .map((x) => x.replace(/^update_/, '')),
  };

  const totalPages = members.pageInfo?.lastPage ?? 0;

  return (
    <div className={s.root}>
      <div className={s.header}>
        <Breadcrumbs
          crumbs={[
            {
              title: name,
              href: htmlUrl,
            },
          ]}
        />
        {team.internal_network && (
          <Link
            className={s.headerLink}
            href={`/search?networks[]=${team.internal_network.id}`}
          >
            <FAIcon icon="users-class" /> View Network
          </Link>
        )}
        <Link className={s.headerLink} href={`${htmlUrl}/settings`}>
          <FAIcon icon="cog" /> Settings &amp; Billing
        </Link>
      </div>
      {awaitingMembers}
      <div className={s.counts}>
        <CountBox count={totalMembers} label="Team Members" />
        <CountBox count={stats.expert_request_count} label="Expert Requests" />
        <CountBox count={stats.consultation_count} label="Consultations" />
        <CountBox
          count={formatCredits(creditBalance.cents)}
          warn={creditBalance.cents <= 0}
          linkTo={billingAccount && `${htmlUrl}/settings/credits`}
          label="Credits"
        />
        <div className={s.search}>
          <TextField
            value={nameSearch ?? ''}
            margin="none"
            fullWidth={false}
            onChange={({ target: { value } }) => {
              setPage(1);
              setNameSearch(normalizeSpace(value));
            }}
            placeholder="Filter by name"
          />
        </div>
      </div>
      <Divider />
      <div className={s.members}>
        <div className={s.membersActions}>
          <div className={s.membersActionsTitle}>Team Members</div>
          <Button
            onClick={handleAdd}
            color="white"
            variant="text"
            fontColor={teal500}
            disabled={!perms.add}
            size="small"
          >
            Add
          </Button>
          <AddTeamMemberDialog
            groupId={groupId}
            userFilter={addUserFilter}
            open={addOpen}
            onSubmit={() => {
              handleAddClose();
              setLoading(true);
            }}
            onClose={handleAddClose}
            onRequestDone={fetchMembers}
          />
        </div>
        <div className={s.membersList}>
          {loading ? (
            <div className={s.loading}>
              <CircularProgress size={52} />
              <span>Loading...</span>
            </div>
          ) : (
            members.edges.map(({ node: m }) => (
              <GroupMember
                key={m.id}
                member={m}
                groupId={groupId}
                viewerRole={viewerRole}
                removeGroupMember={
                  perms.remove[m.role] ? removeGroupMemberAndFetch : null
                }
                updateGroupMember={
                  perms.update[m.role] ? updateGroupMemberAndFetch : null
                }
                allowedRoles={perms.allowedRoles}
              />
            ))
          )}
          {totalPages > 1 && (
            <div className={s.pagination}>
              <Pagination
                page={page}
                count={totalPages}
                onChange={(_, value) => setPage(value)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state, ownProps) => ({
    viewer: state.viewer,
    team: (
      state.groups.all.edges.find((g) => g.node.id === ownProps.groupId) || {}
    ).node,
  }),
  {
    removeGroupMember,
    updateGroupMember,
    fetchGroupMembers,
    notify,
  }
)(Team);
