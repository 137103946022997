const platform = () => ({
  text: 'Platform',
  to: '/platform',
});

const becomeAnExpert = () => ({
  text: 'Become an Expert',
  to: `/signup/expert`,
});

const chat = () => ({
  text: 'Live Chat',
  onClick: () => window.Intercom('show'),
  mobileOnly: true,
});

const login = () => ({
  text: 'Log In / Join',
  to: '/login',
  force: true,
  border: true,
  id: 'homeLoginJoin',
});

export const marketingLinks = ({
  showPlatform = true,
  showBecomeAnExpert = true,
  showChat = true,
  showLogin = true,
  ...opts
} = {}) =>
  [
    showPlatform && platform(opts),
    showBecomeAnExpert && becomeAnExpert(opts),
    showChat && chat(opts),
    showLogin && login(opts),
  ].filter(Boolean);
