import React, { PureComponent, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import Waypoint from 'react-waypoint';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment-timezone';
import cx from 'classnames';
import Tooltip from 'rc-tooltip';
import Layout from '../../components/Layout/Layout';
import Body from '../../components/Body/Body';
import Button from '../../components/Button/Button';
import MessageComponent from '../../components/Message';
import LongText from '../../components/LongText';
import Picture from '../../components/Picture';
import Tidbit from '../../components/Tidbit';
import Link from '../../components/Link';
import MaterialIcon from '../../components/Icon/MaterialIcon';
import RequestConsultation from '../../components/RequestConsultation';
import EmptyMessage from '../../components/EmptyMessage';
import MediaQuery from '../../components/MediaQuery';
import CircularProgress from '../../components/CircularProgress';
import SvgIcon from '../../components/Icon/SvgIcon';
import Channel from './Channel';
import TypingIndicator from './TypingIndicator';
import OnlineStatus from './OnlineStatus';
import { isAdvancedUser } from '../../core/group';
import {
  white,
  darkBlue,
  teal500,
  sand,
  lightTan,
  darkGray,
  darkGreen,
  darkBrown,
  black,
} from '../../core/colors';
import { prettyBytes } from '../../core/util';
import {
  isNotAnonymous,
  isAnonymous,
  getChannelId,
  getChannelUrl,
} from '../../core/messaging';
import { SCREEN_SM, SCREEN_MD } from '../../constants';
import {
  createChannel,
  fetchChannel,
  fetchChannels,
  fetchUnreadChannels,
  addMessage,
  sendMessage,
  sendFileMessage,
  fetchLastMessages,
  fetchPreviousMessages,
  markChannelAsRead,
  fetchUnreadCount,
  updateTextInput,
  deleteChannel,
  saveMessagingAttachment,
} from '../../actions/messaging';
import { openFileDialog, notify } from '../../actions/ui';
import s from './Messaging.module.scss';
import { canRequestConsultation } from '../../core/consultation';
import { useApp } from '../../hooks/useAppContext';
import { useNavigate } from 'react-router-dom';

function isMac() {
  return navigator && navigator.platform.indexOf('Mac') > -1;
}

function isWin() {
  return navigator && navigator.platform.indexOf('Win') > -1;
}

class Channels extends PureComponent {
  render() {
    const {
      isMobileVersion,
      channelUrl,
      fetchLastMessages,
      markChannelAsRead,
      messaging,
      onLoad,
      onSelect,
    } = this.props;

    async function onSelectChannel(channel) {
      if (channel) {
        await fetchLastMessages(channel.raw.url);
        await markChannelAsRead(channel.raw.url);
        channel.raw.unreadMessageCount = 0;
      }
      return onSelect();
    }

    return (
      <div className={s.channels}>
        {messaging.channels.map((c) => (
          <Channel
            key={c.raw.url}
            channel={c}
            selected={!isMobileVersion && c.raw.url === channelUrl}
            onSelect={onSelectChannel}
          />
        ))}

        {messaging.channels.length > 0 &&
          messaging.query &&
          messaging.query.hasNext && <Waypoint onEnter={onLoad} />}

        {messaging.loadingChannels && (
          <div className={s.loading}>
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

Channels = connect(undefined, {
  fetchLastMessages,
  markChannelAsRead,
})(Channels);

class Header extends PureComponent {
  render() {
    const { isMobileVersion, user, onRequestConsultation, onBackToChannels } =
      this.props;

    const showTidbit =
      user.city ||
      user.country ||
      (user.languages && user.languages.length > 0);

    return (
      <div className={s.header}>
        {isMobileVersion && (
          <div className={s.backToChannels}>
            <MaterialIcon icon="arrow_back" onClick={onBackToChannels} />
          </div>
        )}
        <div className={s.pictureContainer}>
          <Picture
            user={user.name || user.picture_url ? user : undefined}
            size={isMobileVersion ? 40 : 50}
          />

          {user.connectionStatus === 'online' && (
            <OnlineStatus
              style={isMobileVersion ? { bottom: 0, right: -2 } : undefined}
            />
          )}
        </div>
        <div className={s.messagesTitleContainer}>
          <div className={s.messagesTitle}>
            {user.name ? (
              <Link to={user.html_url}>{user.name}</Link>
            ) : (
              'Confidential Client'
            )}
          </div>
          {showTidbit && (
            <div className={s.messagesSubTitle}>
              <MediaQuery maxWidth={SCREEN_MD}>
                {(isMediumScreen) => (
                  <Tidbit
                    inline={!isMediumScreen}
                    city={user.city}
                    country={user.country}
                    languages={user.languages}
                  />
                )}
              </MediaQuery>
            </div>
          )}
        </div>
        {canRequestConsultation(user) && (
          <div className={s.requestConsultation}>
            <Button
              size={isMobileVersion ? 'small' : 'normal'}
              onClick={onRequestConsultation}
              style={{ fontSize: 14 }}
            >
              Request Call
            </Button>
          </div>
        )}
      </div>
    );
  }
}

class Messages extends PureComponent {
  handlePreviousMessages = (info) => {
    if (info.currentPosition === Waypoint.inside) {
      this.props.onLoad();
    }
  };

  render() {
    const { viewer, user, users, channelUrl, messaging, messagesRef } =
      this.props;

    const messages = channelUrl && messaging.messages[channelUrl];
    const channel = channelUrl && messaging.channelIndex[channelUrl];

    return (
      <div className={s.messagesContainer} ref={messagesRef}>
        <div className={s.messages}>
          {(messaging.loadingChannels || (messages && messages.loading)) && (
            <div className={s.loading}>
              <CircularProgress />
            </div>
          )}

          {channelUrl && messaging.channels.length > 0 && (
            <Waypoint key={channelUrl} onEnter={this.handlePreviousMessages} />
          )}

          {messages &&
            messages.messages.map((m) => {
              const { userId } = m._sender;

              const sender = channel.raw.memberMap[userId];

              const senderUser =
                isNotAnonymous(channel.raw, userId) && users[sender.userId];

              return (
                <Message
                  key={`message${m.messageId}`}
                  message={m}
                  user={user}
                  sender={senderUser}
                  isRead={channel.raw.getReadReceipt(m) === 0}
                  isOwnMessage={viewer.id === userId}
                />
              );
            })}

          {channel &&
            channel.raw.getTypingMembers().map((m) => {
              const user =
                isNotAnonymous(channel.raw, m.userId) && users[m.userId];

              return (
                <MessageComponent
                  id={`typing${user.id}`}
                  key={`typing${user.id}`}
                  bubbleClassName={s.typingBubble}
                  pictureSize={40}
                  className={cx(s.message, s.messageLeft)}
                  borderColor={lightTan}
                  backgroundColor={lightTan}
                  position="left"
                  align="top"
                  user={user}
                >
                  <TypingIndicator />
                </MessageComponent>
              );
            })}
        </div>
      </div>
    );
  }
}

class Message extends PureComponent {
  render() {
    const { message, user, sender, isRead, isOwnMessage } = this.props;
    const isToday = moment(message.createdAt)
      .startOf('day')
      .isSame(moment().startOf('day'));
    const dateFormat = isToday ? 'h:mma' : 'D MMM • h:mma';
    const showReadReceipt = isOwnMessage && isRead;

    const messageProps = isOwnMessage
      ? {
          color: white,
          borderColor: darkGreen,
          backgroundColor: darkGreen,
          position: 'right',
          icon: sender ? undefined : (
            <SvgIcon icon="incognito" width={28} height={28} fill={white} />
          ),
        }
      : {
          color: darkBrown,
          borderColor: sand,
          backgroundColor: sand,
          position: 'left',
        };

    return (
      <div
        className={cx(
          s.message,
          isOwnMessage ? s.messageRight : s.messageLeft,
          {
            [s.messageRead]: showReadReceipt,
          }
        )}
      >
        <MessageComponent
          id={`message${message.messageId}`}
          pictureSize={40}
          bubbleClassName={cx(s.messageBubble, {
            [s.ownMessageBubble]: isOwnMessage,
          })}
          align="top"
          user={sender}
          {...messageProps}
        >
          <MessageBody
            isOwnMessage={isOwnMessage}
            user={user}
            sender={sender}
            message={message}
          />
        </MessageComponent>
        <div className={s.messageDate}>
          {moment(message.createdAt).format(dateFormat)}
          {showReadReceipt && (
            <MaterialIcon
              className={s.readReceipt}
              icon="done"
              color={teal500}
            />
          )}
        </div>
      </div>
    );
  }
}

const MessageBody = ({ isOwnMessage, user, sender, message }) => {
  if (message.isFileMessage()) {
    return <Attachment isOwnMessage={isOwnMessage} message={message} />;
  }

  if (message.customType === 'consultation_feedback') {
    return (
      <ConsultationFeedback
        isOwnMessage={isOwnMessage}
        user={user}
        sender={sender}
        message={message}
      />
    );
  }

  return <LongText linkify text={message.message} />;
};

const Attachment = ({ isOwnMessage, message }) => (
  <div className={s.fileLink}>
    <Link to={`${message.url}?dl=true`}>
      <MaterialIcon
        color={isOwnMessage ? white : darkGray}
        className={s.fileDownloadIcon}
        icon="file_download"
      />
    </Link>
    <Link newTab className={s.fileName} to={message.url}>
      {message.name || 'Attachment'}
    </Link>
    {message.size > 0 && (
      <span className={s.fileSize}>
        {prettyBytes(message.size).toUpperCase()}
      </span>
    )}
  </div>
);

const ConsultationFeedback = ({ isOwnMessage, user, sender, message }) => {
  const userName = user.first_name || user.name;
  const senderName = sender ? sender.first_name || sender.name : '';
  let data;
  try {
    data = JSON.parse(message.data);
  } catch (err) {
    Promise.reject(err);
  }

  const title =
    data && data.onfrontiers_feedback
      ? 'Private feeback to OnFrontiers'
      : isOwnMessage
        ? `Private feedback to ${userName || 'client'}`
        : `${senderName || 'Client'}'s private feedback for you`;

  return (
    <div>
      <b>{title}: </b>
      <LongText linkify text={message.message} style={{ display: 'inline' }} />
      {data && data.consultation_id && (
        <div>
          <br />
          <Link to={`/consultation/${data.consultation_id}`}>
            View Consultation
          </Link>
        </div>
      )}
    </div>
  );
};

class MessageInput extends PureComponent {
  constructor(props) {
    super(props);

    const { viewer } = props;

    const sendAnonymously =
      viewer.groups && viewer.groups.some((g) => g.default_anonymous_messaging);

    this.state = {
      sendAnonymously,
    };
  }

  setAnonymous(channelUrl) {
    const { messaging } = this.props;

    const channel = channelUrl && messaging.channelIndex[channelUrl];

    if (
      channel &&
      channel.raw.lastMessage &&
      channel.raw.lastMessage.messageId
    ) {
      this.setState({ sendAnonymously: false });
    }
  }

  componentDidMount() {
    this.setAnonymous(this.props.channelUrl);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.channelUrl !== this.props.channelUrl) {
      this.setAnonymous(this.props.channelUrl);
    }
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
      if (this.props.text && this.props.text.trim()) {
        this.handleSend();
      }
    }
  };

  handleSend = () => {
    this.props.onSend(this.state.sendAnonymously);
  };

  renderAnonymousOption = () => {
    const { messaging, channelUrl, viewer } = this.props;

    if (!isAdvancedUser(viewer)) return;

    const channel = channelUrl && messaging.channelIndex[channelUrl];

    if (!channel) return;

    if (isAnonymous(channel.raw, viewer.id)) {
      return (
        <div className={s.anonymousMode}>
          <SvgIcon
            icon="incognito"
            width={18}
            height={18}
            fill={black}
            style={{ marginRight: 3 }}
          />{' '}
          Anonymous mode
        </div>
      );
    }

    return (
      <div>
        <Tooltip
          overlay="A new confidential channel will be used"
          trigger={['hover']}
          overlayStyle={{ zIndex: 2000 }}
          placement="top"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.sendAnonymously}
                onChange={() =>
                  this.setState((prevState) => ({
                    sendAnonymously: !prevState.sendAnonymously,
                  }))
                }
              />
            }
            label="Send anonymously"
            style={{ minWidth: 215, marginBottom: 10 }}
          />
        </Tooltip>
      </div>
    );
  };

  render() {
    const {
      isMobileVersion,
      text,
      onChange,
      onAttachFile,
      messaging,
      channelUrl,
      inputRef,
    } = this.props;

    const messages = channelUrl && messaging.messages[channelUrl];

    const hasText = text && text.trim();

    const disabled =
      messaging.sendingMessage ||
      messaging.loadingChannels ||
      messaging.creatingChannel ||
      messaging.fetchingChannel ||
      (messages && messages.loading) ||
      !hasText ||
      !channelUrl;

    const isDesktopVersion = !isMobileVersion;

    return (
      <div className={s.messageInput}>
        <div className={s.messageInputText}>
          <TextField
            id="writeYourMessage"
            fullWidth
            placeholder="Write your message"
            multiline
            minRows={1}
            maxRows={6}
            value={text}
            onChange={onChange}
            InputProps={{ disableUnderline: true }}
            onKeyDown={this.handleKeyPress}
            ref={inputRef}
          />
        </div>

        <div className={s.actions}>
          <IconButton
            style={
              isDesktopVersion ? { padding: 0, width: 35, height: 35 } : {}
            }
            onClick={onAttachFile}
          >
            <MaterialIcon icon="attach_file" color={darkGreen} />
          </IconButton>
          <div className={s.sendAction}>
            {this.renderAnonymousOption()}
            {isDesktopVersion && isMac() && (
              <span className={s.sendHelpText}>Press CMD+Enter</span>
            )}
            {isDesktopVersion && isWin() && (
              <span className={s.sendHelpText}>Press CTRL+Enter</span>
            )}
            {isMobileVersion ? (
              <IconButton disabled={disabled} onClick={this.handleSend}>
                <MaterialIcon icon="send" color={darkGreen} />
              </IconButton>
            ) : (
              <Button
                disabled={disabled}
                primary
                size="small"
                label="Send"
                onClick={this.handleSend}
                style={{ marginLeft: 5 }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

class Widget extends PureComponent {
  state = {};

  render() {
    const {
      isMobileVersion,
      user,
      users,
      viewer,
      messaging,
      channelUrl,
      text,
      onPreviousMessages,
      onBackToChannels,
      onRequestConsultation,
      messagesRef,
      textInputRef,
      onSend,
      onAttachFile,
      onInputChange,
    } = this.props;

    const messages = channelUrl && messaging.messages[channelUrl];

    return (
      <div className={s.widget}>
        {user && (
          <Header
            isMobileVersion={isMobileVersion}
            onBackToChannels={onBackToChannels}
            onRequestConsultation={onRequestConsultation}
            user={user}
          />
        )}

        {messages && messages.messages.length > 0 ? (
          <Messages
            isMobileVersion={isMobileVersion}
            viewer={viewer}
            user={user}
            users={users}
            channelUrl={channelUrl}
            messaging={messaging}
            messagesRef={messagesRef}
            onLoad={onPreviousMessages}
          />
        ) : (
          user && (
            <EmptyMessage
              style={{
                padding: '60px 10px 0',
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'scroll',
              }}
              bodyStyle={{ margin: 0, fontSize: 18 }}
              border={false}
              iconName="chat"
              iconColor={darkBlue}
              title={`Connected to ${user.name || 'Confidential Client'}.`}
              body="Use messages to clarify scope, questions, and expertise before your call begins."
            />
          )
        )}

        {user && (
          <MessageInput
            viewer={viewer}
            text={text}
            onChange={onInputChange}
            onSend={onSend}
            onAttachFile={onAttachFile}
            messaging={messaging}
            channelUrl={channelUrl}
            isMobileVersion={isMobileVersion}
            inputRef={textInputRef}
          />
        )}
      </div>
    );
  }
}

const Messaging = (props) => {
  const context = useApp();
  const navigate = useNavigate();
  const hasLoadedFirstChannel = useRef(false);
  const [messagesScroll, setMessagesScroll] = useState(null);
  const [textInputRef, setTextInputRef] = useState(null);
  const [requestConsultationOpen, setRequestConsultationOpen] = useState(false);
  const [mobileChannelSelected, setMobileChannelSelected] = useState(
    !!props.mobileChannelSelected
  );

  const {
    addMessage,
    fetchChannel,
    fetchUnreadCount,
    markChannelAsRead,
    channelUrl,
    messaging,
    viewer,
    users,
    userContext,
    fetchChannels,
  } = props;
  const { loadingChannels, fetchingChannel, channels, offline, messages } =
    messaging;

  useEffect(() => {
    const { sendBird } = context;

    loadChannels().then(() => {
      scrollToBottom();
      focusTextInput();
    });

    sendBird.createSession().then((session) => {
      if (!session) return;
      const channelHandler = new session.ChannelHandler();

      channelHandler.onMessageReceived = (rawChannel, message) => {
        fetchChannel(rawChannel.url, true);
        addMessage(rawChannel.url, message);
        if (props.channelUrl === rawChannel.url) {
          scrollToMessage(message);
          markChannelAsRead(rawChannel.url).then(() => fetchUnreadCount());
        }
      };

      channelHandler.onReadReceiptUpdated = (rawChannel) => {
        fetchChannel(rawChannel.url, true);
      };

      channelHandler.onTypingStatusUpdated = (rawChannel) => {
        fetchChannel(rawChannel.url);
        const scroll = messagesScroll;
        const isBottomNear =
          scroll &&
          scroll.scrollHeight - scroll.scrollTop - scroll.clientHeight < 300;
        if (isBottomNear) scrollToBottom();
      };

      session.addChannelHandler('Messages', channelHandler);
    });

    return () => {
      sendBird.createSession().then((session) => {
        if (!session) return;
        session.removeChannelHandler('Messages');
      });
    };
  }, [context]);

  if (!channelUrl && !hasLoadedFirstChannel.current) {
    const firstChannel = messaging.channels && messaging.channels[0];
    if (firstChannel) {
      hasLoadedFirstChannel.current = true;
    }
  }

  const getUser = () => {
    const { users, viewer, messaging, channelUrl } = props;

    if (!channelUrl) return;

    const channel = messaging.channelIndex[channelUrl];
    if (!channel) return;

    const member = channel.raw.members.find((m) => m.userId !== viewer.id);
    if (!member) return;

    if (isAnonymous(channel.raw, member.userId)) {
      return {
        connectionStatus: member.connectionStatus,
      };
    }

    return {
      connectionStatus: member.connectionStatus,
      ...users[member.userId],
    };
  };

  function scrollToBottom() {
    if (messagesScroll) {
      messagesScroll.scrollTop = messagesScroll.scrollHeight;
    }
  }

  function focusTextInput() {
    if (textInputRef) {
      textInputRef.focus();
    }
  }

  const loadChannels = async () => {
    const {
      channelUrl,
      fetchUnreadChannels,
      fetchLastMessages,
      markChannelAsRead,
      messaging,
    } = props;

    if (messaging.loadedChannels) {
      await fetchUnreadChannels();
    }

    const firstChannel = messaging.channels && messaging.channels[0];

    if (!channelUrl && firstChannel) {
      await fetchLastMessages(firstChannel.raw.url);
      await markChannelAsRead(firstChannel.raw.url);
      scrollToBottom();
      setHasLoadedFirstChannel(true);
    }
  };

  let endTypingTimeout = null;
  const handleMessageInput = ({ target: { value: text } }) => {
    const { channelUrl, messaging, updateTextInput } = props;

    updateTextInput(channelUrl, text);

    const channel = messaging.channelIndex[channelUrl];
    channel.raw.startTyping();
    if (endTypingTimeout) {
      clearTimeout(endTypingTimeout);
    }
    endTypingTimeout = setTimeout(() => {
      channel.raw.endTyping();
    }, 3000);
  };

  const handleMessageSend = async (createAnonymousChannel) => {
    const {
      createChannel,
      sendMessage,
      messaging,
      channelUrl,
      updateTextInput,
      users,
      viewer,
      notify,
      fetchChannel,
      deleteChannel,
    } = props;

    try {
      const channel = messaging.channelIndex[channelUrl];
      channel.raw.endTyping();

      if (createAnonymousChannel) {
        const member = channel.raw.members.find((m) => m.userId !== viewer.id);

        const user = users[member.userId];

        const anonymousChannelId = await createChannel(
          [viewer.id, user.id],
          true
        );

        const anonymousChannelUrl = getChannelUrl(anonymousChannelId);

        await fetchChannel(anonymousChannelUrl);

        const messages = messaging.messages[channelUrl];

        await sendMessage(anonymousChannelUrl, messages.textInput);

        updateTextInput(channelUrl, '');

        if (!channel.raw.lastMessage || !channel.raw.lastMessage.messageId) {
          deleteChannel(channelUrl);
        }

        return navigate(`/messaging/${anonymousChannelId}?channel=true`);
      }

      const message = await sendMessage(channelUrl);

      updateTextInput(channelUrl, '');

      scrollToMessage(message);
    } catch (err) {
      notify('An error occurred when sending message', 'error');
      throw err;
    }
  };

  const handlePreviousMessages = () => {
    const { fetchPreviousMessages, channelUrl } = props;

    fetchPreviousMessages(channelUrl).then((messages) => {
      const message = messages && messages[messages.length - 1];
      scrollToMessage(message);
    });
  };

  const scrollToMessage = (message) => {
    if (message) {
      const element = document.getElementById(`message${message.messageId}`);
      if (element) element.scrollIntoView();
    }
  };

  const handleRequestConsultation = () => setRequestConsultationOpen(true);
  const closeRequestConsultation = () => setRequestConsultationOpen(false);
  const handleChannelSelect = () => setMobileChannelSelected(true);
  const handleBackToChannels = () => setMobileChannelSelected(false);

  const handleAttachFile = () => {
    const {
      openFileDialog,
      channelUrl,
      sendFileMessage,
      saveMessagingAttachment,
    } = props;

    openFileDialog({
      fromSources: [
        'local_file_system',
        'url',
        'googledrive',
        'dropbox',
        'box',
        'onedrive',
        'onedriveforbusiness',
      ],
      accept: [
        'image/*',
        '.txt',
        '.rtf',
        '.pdf',
        '.csv',
        '.tsv',
        '.xls',
        '.xlsx',
        '.doc',
        '.docx',
        '.ppt',
        '.pptx',
      ],
    }).then(async (file) => {
      if (file) {
        const encodedFileURL = await saveMessagingAttachment(
          channelUrl,
          file.url
        );

        sendFileMessage(
          channelUrl,
          encodedFileURL,
          file.filename,
          file.mimetype,
          file.size
        ).then((message) => {
          scrollToMessage(message);
        });
      } else {
        notify('File upload failed.', 'error');
        throw new Error('File is empty');
      }
    });
  };

  const user = getUser();
  const firstLoad =
    (loadingChannels || fetchingChannel) && channels.length === 0;
  const empty = !loadingChannels && !fetchingChannel && channels.length === 0;

  const textInput =
    channelUrl && messages[channelUrl] && messages[channelUrl].textInput;

  return (
    <Layout
      showNav
      showFooter={false}
      verticalCenter={firstLoad || empty || offline}
      contentClassName={s.layoutContent}
      selected="messaging"
    >
      <MediaQuery maxWidth={SCREEN_SM}>
        {(isMobileVersion) => {
          if (firstLoad) {
            return (
              <div style={{ marginBottom: 100 }} className={s.loading}>
                <CircularProgress />
              </div>
            );
          }

          if (offline) {
            return (
              <EmptyMessage
                style={{
                  padding: 10,
                  margin: '0 auto 100px',
                  maxWidth: 600,
                }}
                border={false}
                iconName="cloud_off"
                iconColor={darkBlue}
                title="Sorry, messaging is temporarily unavailable."
              />
            );
          }

          if (empty) {
            const emptyBody =
              userContext === 'expert'
                ? 'Communicate with clients to clarify your experience or answer scoping questions prior to consultation.'
                : 'Communicate with any Expert to clarify their experience or ask scoping questions prior to requesting a consultation.';
            return (
              <EmptyMessage
                style={{
                  padding: 10,
                  margin: '0 auto 100px',
                  maxWidth: 600,
                }}
                border={false}
                iconName="chat"
                iconColor={darkBlue}
                title="No messages here."
                body={emptyBody}
              />
            );
          }

          const isDesktopVersion = !isMobileVersion;
          const isChannelVisible =
            isDesktopVersion || !user || !mobileChannelSelected;
          const isWidgetVisible =
            isDesktopVersion || (user && mobileChannelSelected);

          return (
            <Body className={s.layoutBody}>
              {isChannelVisible && (
                <Channels
                  isMobileVersion={isMobileVersion}
                  channelUrl={channelUrl}
                  messaging={messaging}
                  onLoad={fetchChannels}
                  onSelect={handleChannelSelect}
                />
              )}

              {isWidgetVisible && (
                <Widget
                  isMobileVersion={isMobileVersion}
                  users={users}
                  user={user}
                  viewer={viewer}
                  messaging={messaging}
                  channelUrl={channelUrl}
                  text={textInput}
                  onRequestConsultation={handleRequestConsultation}
                  onBackToChannels={handleBackToChannels}
                  onSend={handleMessageSend}
                  onAttachFile={handleAttachFile}
                  onInputChange={handleMessageInput}
                  onPreviousMessages={handlePreviousMessages}
                  messagesRef={setMessagesScroll}
                  textInputRef={setTextInputRef}
                />
              )}

              {requestConsultationOpen && (
                <RequestConsultation
                  open={requestConsultationOpen}
                  onClose={closeRequestConsultation}
                  expertId={user.id}
                  profile={user.profile}
                />
              )}
            </Body>
          );
        }}
      </MediaQuery>
    </Layout>
  );
};

export default connect(
  (state) => ({
    users: state.users,
    viewer: state.viewer,
    messaging: state.messaging,
    userContext: state.ui.userContext,
  }),
  {
    fetchChannels,
    fetchChannel,
    fetchUnreadChannels,
    addMessage,
    sendMessage,
    sendFileMessage,
    fetchLastMessages,
    fetchPreviousMessages,
    openFileDialog,
    notify,
    markChannelAsRead,
    fetchUnreadCount,
    updateTextInput,
    createChannel,
    deleteChannel,
    saveMessagingAttachment,
  }
)(Messaging);
